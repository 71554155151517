<template>
  <div id="news-list">
    <Breadcrumbs
      :crumbs="[
        { name: globals.info.school_name, link: `${pathPrefix}/` },
        { name: getSectionName, link: `${pathPrefix}/news` },
      ]"
    />
    <div class="page-container">
      <div
        v-if="filters && filters.length > 1"
        class="filter"
      >
        <select
          v-model="selectedFilter"
          name="filter"
          :aria-label="$t('pages.news.newsFilter')"
          @change="filterNews"
        >
          <option
            v-for="filter in filters"
            :key="filter.id"
            :value="filter.id"
          >
            {{ filter.name }}
          </option>
        </select>
      </div>
      <LoadingIndicator v-if="loading" />
      <div
        v-for="article in news"
        v-else
        :key="article.id"
        class="page-container-item"
      >
        <div
          v-if="article.cover_image"
          class="article-image"
        >
          <img
            :alt="article.image_description || article.title"
            :src="article.cover_image"
          />
        </div>
        <div class="article-info">
          <div class="title">
            <h2>
              <nuxt-link :to="`${pathPrefix}/article/${article.id}`">
                {{ replaceHTMLEntities(article.title) }}
              </nuxt-link>
            </h2>
          </div>
          <div class="article-date">
            {{ formatArticleDate(article) }}
          </div>
          <div class="content">
            {{ replaceHTMLEntities(article.snippet) }}
          </div>
          <div class="read-more">
            <nuxt-link
              :aria-label="$t('pages.news.readMoreAboutLabel', { title: article.title })"
              :to="`${pathPrefix}/article/${article.id}`"
            >
              {{ $t('pages.news.readMoreUppercase') }} <span>&gt;</span>
            </nuxt-link>
          </div>
        </div>
      </div>

      <pagination
        v-if="totalPages > 1"
        :current-page="currentPage"
        :total-pages="totalPages"
      />
    </div>
  </div>
</template>

<script>
import { defineNuxtComponent, useHead, useAsyncData } from '#imports';
import { mapState, mapGetters, useStore } from 'vuex';
import { useIntranet } from '@/composables/useIntranet';
import Breadcrumbs from '../../components/global/Breadcrumbs.vue';
import LoadingIndicator from '../../components/global/LoadingIndicator.vue';
import Pagination from '../../components/global/Pagination.vue';
import { replaceHTMLEntities } from '../../helpers/strings';
import { getMetadataAndTags } from '../../helpers/metadata';
import { setupAndFetchArticles } from '../../helpers/section/sections.helper';
import { clientWorkWindowEvent } from '../../helpers/event-dispatch-helpers';

export default defineNuxtComponent({
  name: 'NewsList',
  components: {
    Breadcrumbs,
    LoadingIndicator,
    Pagination,
  },
  beforeRouteUpdate(to, from, next) {
    if (
      import.meta.client &&
      (to.query.page_no !== from.query.page_no || to.query.filter_id !== from.query.filter_id)
    ) {
      this.fetchNews(to.query);
      next();
    } else {
      next();
    }
  },
  data() {
    return {
      loading: false,
      news: [],
      filters: [],
      bottom: false,
      pageNumber: '1',
      selectedFilter: '',
      nextPageUrl: '',
    };
  },
  async setup() {
    const store = useStore();
    const { handleLayout } = useIntranet();

    onMounted(() => {
      handleLayout();
    });

    const { data } = await useAsyncData('news', () =>
      setupAndFetchArticles(store, useRoute().query, useNuxtApp().$axios),
    );
    const metaDataInfo = getMetadataAndTags(store.state.settings.metadata, 'articles', store.getters.getSectionName);

    useHead({
      ...metaDataInfo.metaTitle,
      meta: metaDataInfo.metaTags,
    });

    return data.value;
  },
  computed: {
    ...mapState(['globals', 'pathPrefix']),
    ...mapGetters(['getSectionName']),
  },
  watch: {
    '$route.query.page_no': {
      handler(oldValue, newValue) {
        if (import.meta.client && oldValue !== newValue) {
          window.scrollTo(0, 0);
        }
      },
    },
  },
  async mounted() {
    clientWorkWindowEvent('news-list');
  },
  methods: {
    replaceHTMLEntities,
    async fetchNews(query) {
      this.loading = true;
      this.totalPages = 0;
      const { news, filters, selectedFilter, totalPages, currentPage } = await setupAndFetchArticles(
        this.$store,
        query,
        this.$axios,
      );
      this.news = news;
      this.filters = filters;
      this.selectedFilter = selectedFilter;
      this.totalPages = totalPages;
      this.currentPage = currentPage;
      this.loading = false;
    },
    filterNews() {
      return navigateTo({ query: { filter_id: this.selectedFilter } });
    },
    formatArticleDate(article) {
      if (!article.published_at) return '';
      return this.$d(article.published_at, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
  },
});
</script>

<style scoped lang="scss">
#news-list {
  .article-image {
    width: 20%;
    float: left;
    height: auto;
    background: #fff;
    padding-right: 20px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .article-info {
    margin-left: 2px;
    width: 75%;
    float: left;
    .title {
      margin-bottom: 10px;
      h2 {
        font-weight: 400;
        font-size: 24px;
        margin: 0;
      }
      a {
        color: var(--primary-color);
        border: none;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .article-date {
      font-size: 12px;
      color: #717171;
      margin-bottom: 10px;
    }
    .content {
      font-size: 14px;
      color: #717171;
      line-height: 28px;
      margin: 0;
    }
    .read-more {
      margin-top: 25px;
      a {
        color: var(--primary-color);
        text-decoration: none;
        font-size: 14px;
        span {
          display: inline-block;
          vertical-align: top;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
